@font-face {
  font-family: "Siemens";
  font-weight: normal;
  src: url("/fonts/Siemens_Sans_Roman.ttf");
}
@font-face {
  font-family: "Siemens";
  font-weight: bold;
  src: url("/fonts/Siemens_Sans_Bold.ttf");
}
body {
  color: rgb(57, 68, 72);
}
.button_chat {
  background: #41aaaa;
  font-family: "Siemens", Helvetica, Arial, sans-serif;
}
.back_chat {
  background-color: rgb(255, 255, 255);
  padding-bottom: 50px;
}
